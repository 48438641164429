
@font-face {
  font-family: 'Kumbh Sans';
  font-weight: 400;
  src: url('../src/fonts/KumbhSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Kumbh Sans';
  font-weight: 700;
  src: url('../src/fonts/KumbhSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 400;
  src: url('../src/fonts/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Dancing Script';
  font-weight: 700;
  src: url('../src/fonts/DancingScript-Bold.ttf') format('truetype');
}

html, body {
  margin: 0;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: 400;
  color: var(--primary-color);
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

