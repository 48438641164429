.arrow_link {
    display: block;
}

.title {
    font-family: Nunito;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 40px;
    margin-bottom: 34px;
}

.wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
    width: 100%;
    margin-top: 20px;
}

.loader {
    margin:  100px auto;
    height: 80px;
    width: 80px;
    border-radius: 9px;
    color: var(--primary-color);
    
  }
  

.grid_container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    margin-bottom: 70px;
}

.embroidery_image {
    grid-column: span 6;
    width: 100%;
    max-width: 100%;
    height: auto;
}

.info_container {
    grid-column: span 6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
    margin-bottom: 18px;
}

.options {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
    margin-bottom: 10px;
}

.customText {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
    margin-bottom: 10px;
}

.btn_download {
    margin-top: 10px;
    width: 228px;
    padding: 18px 75px;
    text-align: center;
    border-radius: 15px;
    background-color: var(--accent-color);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    border: none;
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.btn_download:hover {
    background-color: var(--primary-color);
    color: var( --secondary-color);
}

.border {
    background-image: url('../../assets/border_repeat-01.svg');
    background-repeat: space;
    background-position: center;
    height: 41px;
    margin-top: 64px;
    margin-bottom: 64px;
}

@media screen and (max-width: 800px) {

    .wrapper {
        margin-top: 54px;
    }

    .arrow_link {
        margin-top: 64px;
    }

    title {
        font-size: 34px;
    }

    .embroidery_image {
        grid-column: span 12;
        margin-bottom: 10px;
    }

    .info_container {
        grid-column: span 12;
    }


}

@media screen and (max-width: 500px) {
    .title {
        font-size: 34px;
    }

    .btn_download {
        width: auto;
    }
}