.wrapper {
    max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  min-height: 80vh;

}

.about {
    margin-bottom: 10px;
}

.border {
    margin-top: auto;
}

.link {
    text-decoration: underline; /* Добавляет подчеркивание к тексту */
  }
  

@media screen and (max-width: 800px) {
    .wrapper {
        margin-top: 54px;
    }
}