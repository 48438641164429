.border {
    background-image: url('../../assets/border_repeat-01.svg');
    background-repeat: round;
    height: 41px;
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .last_border {
     background-image: url('../../assets/border_repeat-01.svg');
     background-repeat: round;
    height: 41px;
    margin-top: 64px;
    margin-bottom: 0px;
  }