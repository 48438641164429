.footer {
    background-color: #646490;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }
  
  .footer_text {
    color: #fff;
    font-size: 18px;
    font-weight: normal;
  }
  