
.header_wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
}

.header {
    width: 100%;
}

.box {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var( --border-color);
}

.logo_image {
    display: flex;
    align-items: center;
    margin-right: 58px;
    width: 80px;
    height: auto;
}

.logo_image:hover {
    cursor: pointer;
}

/* CSS для пункта меню */
.menu_item {
    font-size: 18px;
    font-weight: 700;
    position: relative;
}

/* CSS для оранжевой черты */
.menu_item::after {
    content: "";
    position: absolute;
    left: 0;
    top: 54px;
    width: 100%;
    height: 4px;
    background-color: var(--accent-color);
    opacity: 0;
    transition: opacity 0.3s ease;
}


.active_link {
    font-size: 18px;
    font-weight: 700;
    position: relative;
    color: var( --accent-color);
    cursor: default;
}


/* CSS для анимации при наведении */
.menu_item:hover::after,
.menu_item:focus::after {
    opacity: 1;
}


.menu {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 34px;
}

.mobile_btn {
    display: none;
}

@media screen and (max-width: 800px) {
    /* .box {
        height: 60px;
    } */

    .header {
        position: fixed;
        background-color: white;
    }

    .logo_image {
        margin-left: 55px;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .overlay_active {
        visibility: visible;
        opacity: 1;
    }

    .mobile_btn {
        display: block;
        position: absolute;
        left: 24px;
        top: 40px;
        cursor: pointer;
        z-index: 11;
    }

    .menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        left: -100%;
        right: 0;
        top: 0;
        bottom: 0;
        width: 250px;
        height: 100vh;
        background-color: white;
        z-index: 10;
        transition: left 0.3s;
    }

    .active_mobile {
        left: 0;
    }

    .menu a {
        font-size: 28px;
    }
}