.home_card {
    height: 100%;
    width: 100%;
    background-color: var(--secondary-color);
    grid-column: span 4;
    padding: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    /* justify-content: space-between; */
}

.card_image {
    width: 100%;
    max-height: 240px;
    object-fit: cover;
    margin-bottom: 10px;
}

.card_title {
    font-family: Nunito;
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 1000px) {
    .home_card {
        grid-column: span 6; 
    }

    .card_image {
        max-height: none;
    }
}

@media screen and (max-width: 600px) {
    .home_card {
        grid-column: span 12; 
        max-height: none;
    }

}