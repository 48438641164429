.root {
  padding: 20px;
  background-color: var( --primary-color);;
  z-index: 49;
  color: var( --secondary-color);
  pointer-events: initial;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.notifikation {
  margin-right: 16px;
  font-size: 16px;
}

.button  {
  padding: 8px 16px;
  text-align: center;
  border-radius: 15px;
  background-color: transparent;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid var( --secondary-color);
  color: var( --secondary-color);
  cursor: pointer;
}

.button:hover {
  background-color: var( --secondary-color);
  color: var( --primary-color);
}

@media (max-width: 768px) {
  .notifikation {
    font-size: 14px;
  }
}