.card {
    height: 100%;
    width: 100%;
    background-color: var(--secondary-color);
    grid-column: span 4;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
}

.card_image {
    width: 100%;
    max-height: 240px;
    object-fit: cover;
    margin-bottom: 10px;
}

.card_title {
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;/* Запрещаем перенос текста */
    overflow: hidden;/* Скрываем текст, который не помещается в одну строку */
    text-overflow: ellipsis; /* Добавляем многоточие в конце обрезанного текста */
}

@media screen and (max-width: 800px) {
    .card {
        grid-column: span 6;
    }
}

@media screen and (max-width: 500px) {
    .card {
        grid-column: span 12;
    }
    .card_image {
        max-height: none;
    }
}