.title {
    font-family: Nunito;
    font-size: 50px;
    font-weight: normal;
    margin-top: 40px;
    margin-bottom: 34px;
}

.wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
    width: 100%;
    margin-top: 20px;
}

.cards_container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 34px;
    grid-row-gap: 34px;
}

.customText {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
    margin-bottom: 20px;
}


.border {
    background-image: url('../../assets/border_repeat-01.svg');
    background-repeat: space;
    background-position: center;
    height: 41px;
    margin-top: 64px;
    margin-bottom: 64px;
  }

@media screen and (max-width: 800px) {
    .wrapper {
        margin-top: auto;
    }

    .title {
        font-size: 36px;
        font-style: normal;
        margin-bottom: auto;
    }

    .cards_container {
        grid-column-gap: 10px;
    }
}

@media screen and (max-width: 500px) {
    .cards_container {
        grid-column-gap: 0px;
    }
}