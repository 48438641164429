.wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
    width: 100%;
    margin-top: 20px;
}

.home_intro {
    margin-top: 64px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
}

.intro_container {
    display: flex;
    flex-direction: column;
    grid-column: span 5;
    padding-top: 50px;
}

.intro_title {
    color: #646390;
    font-family: Nunito;
    font-size: 46px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 24px;
}

.additional_text {
    font-size: 30px;
}

.intro {
    font-size: 18px;
   
}

.intro_image {
    grid-column: 7 / 13;
    width: 100%;
    max-width: 100%;
    height: auto;
}



.home_info {
    display: flex;
    flex-direction: column;

}

.info_image {
    margin: 0 auto;
    max-width: 588px;
    max-height: 397px;
    width: 100%;
    height: 100%;
}

.info_title,
.home_title {
        margin-top: 34px;
        font-family: Nunito;
        font-size: 36px;
        font-style: normal;
        margin-bottom: 34px;
}

.info {
    font-size: 18px;
    line-height: 148%;
}

.home_cards {
    display: grid;
    margin-top: 34px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
}

.description {
    display: grid;
    margin-top: 34px;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
}

.description_texts {
    grid-column: span 7;
    font-size: 16px;
    line-height: 148%;
    font-size: 18px;
}

.description_card {
    grid-column: span 5;
}

.card_image {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.card_title {
    font-family: Nunito;
    font-size: 24px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
    .intro_title {
        font-size: 34px;
    }

    .intro_container {
        grid-column: span 6;
    }

    .border {
        margin-top: 10px;
        margin-bottom: 10px;
    }

}

@media screen and (max-width: 800px) {
    .wrapper {
        margin-top: 54px;
    }

    .intro_image {
        margin: 0 auto;
        max-width: 588px;
        max-height: 397px;
        width: 100%;
        height: 100%;
    }

    .intro {
        font-size: 18px;
    }

    .home_intro {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        margin-top: 0;
    }

    .intro {
        margin-bottom: 10px;
    }

    .description {
        display: flex;
        flex-direction: column;
        margin-top: auto;
    }

    .description_card {
        margin-top: 10px;
    }

    .description_texts {
        font-size: 18px;
    }

}